/* eslint-disable */
import React from 'react';

import { Button, Paper, Slide, Typography } from '@mui/material';
import { priceFormatter } from '../../common/Utils';


const ConfirmPopUp = ({ slideOpen, handleConfirmBooking, totalTripFare, newTotalTripFare, handleCancleConfirmBooking}) => {
    let newTotalTripFareCopy = newTotalTripFare || ""

    return (
        <div style={{position: 'fixed', top:"0", bottom:"0", right:"0", left:"0", background:"#80808078", display: slideOpen ? "" : "none"}} >
        <div style={{position:"absolute", bottom:"0", right:"0", left:"0", minHeight: "340px", margin: "auto", maxWidth:"640px", borderRadius:"16px 16px 0 0", background:"#FFF", display: slideOpen ? "" : "none"}}>
            <Slide direction={"up"} in={slideOpen} className={"sliderStationList"} id='slider-container' >
                <Paper id="slider-paper" style={{height: slideOpen ? "100%" : "0%"}} >
                    <div style={{padding:"16px", paddingTop:"0"}} >

                        

                        <div className={"selectStationSliderHeader"}>
                            <Typography children={"Konfirmasi"} className='popUpHeaderText' id="headerText" />
                        </div>

                        <div className={"stationlistContainer"}>
                            <Typography>Harga tiket mengalami perubahan dari <br></br><Typography style={{fontWeight: "bold"}}>{`Rp ${priceFormatter(totalTripFare)}`}</Typography> menjadi <Typography style={{fontWeight: "bold"}}>{`Rp ${priceFormatter(newTotalTripFareCopy.split(".")[0])}`}. </Typography> 
                            Apakah bersedia untuk melanjutkan?</Typography>
                        </div>

                        <Button children={"Ya, lanjutkan"} fullWidth variant={"text"} className={"submitButton"} onClick={()=>{handleConfirmBooking()}} id="CariTiketPesawat"/>
                    </div>
                </Paper>
            </Slide>
        </div>
        </div>
    )
}

export default ConfirmPopUp;