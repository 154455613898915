import { Typography } from "@mui/material";
import React from "react";

const BaggageSummaryItem = ({summary}) => {
  return (
    <div
      className="bag-select-summary-item"
    >
      <div className="bag-airports">
        <Typography className="label-2">{summary.depAirport}</Typography>
        <span>&nbsp; - &nbsp;</span>
        <Typography className="label-2">{summary.arrAirport}</Typography>
      </div>
      <div className="label-3"><Typography>Rp {summary.price}</Typography></div>
    </div>
  );
};

export default BaggageSummaryItem;
