import { createTheme } from '@mui/material/styles'

const palette = {
  // BUTTONS
  primary: {
    main: '#00BFB2',
    contrastText: '#fff'
  },
  secondary: {
    main: '#00BFB2'
  },
  disabled: {
    main: 'rgba(0, 0, 0, 0.2)'
  }
}

const typography = {
  fontFamily: `'DM Sans', sans-serif`
}

// Create a theme instance.
const theme = createTheme({
  palette: palette,
  DialogTitle: {
    fontFamily: `'Poppins', sans-serif`
  },
  DialogContentText: {
    fontFamily: `'Poppins', sans-serif`
  },
  typography,
  components: {
    MuiButton: {
      styleOverrides: {
        root: ({ ownerState, theme }) => ({
          height: '48px',
          marginTop: '20px',
          backgroundColor: theme.palette.primary,
          fontSize: '14px',
          fontWeight: 'bold',
          borderRadius: '8px',
          textTransform: 'capitalize',
          color: theme.palette.primary.contrastText,
          boxShadow: 'none',
          // VARIANT -> CONTAINED
          ...(ownerState.variant === 'contained' && {
            fontSize: ownerState.fullWidth ? '14px' : '12px',
          }),
          ...(ownerState.variant === 'outline' && {
            padding: '12px',
            border: '1px solid #1A1A1A',
            borderRadius: ownerState.borderRadius ? ownerState.borderRadius : '8px'
          }),
          // VARIANT -> TEXT
          ...(ownerState.variant === 'text' && {
            padding: '8px',
            fontSize:
              ownerState.fonttype === 'light' ? '12px' : '14px',
            fontWeight:
              (ownerState.fonttype === 'light' && '400') || '700',
          }),
          // FOR ICONS IN BUTTON
          // INHERIT THE FONT SIZE OF THE BUTTON TEXT
          '& .MuiButton-endIcon': {
            '> :nth-of-type(1)': {
              fontSize: 'inherit',
            },
          },
          '& .MuiButton-startIcon': {
            '> :nth-of-type(1)': {
              fontSize: 'inherit',
            },
          },
          // FOR DISABLED BUTTONS
          '&.Mui-disabled': {
            color: theme.palette.disabled.contrastText,
            backgroundColor: theme.palette.disabled.main,
          },
        }),
      },
    },
  },
})

export default theme
