import React from "react";
import { Typography } from "@mui/material";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import theme from "../../theme";

const PassengerSelectionField = ({
  OnChangePassengerNumber,
  fieldIcon,
  passenger,
  passengerType,
  minPassengerCounts,
  passengerError,
  label,
  subLabel
}) => {
  return (
    <>
      <div className="passengerBlock">
        <div className={"passengerNameBlock"}>
          {fieldIcon}
          <div className="passengerInfo">
            <Typography children={label} className={"stationName"} />
            <Typography
              children={subLabel}
              style={{ fontSize: "12px", color: "#1A1A1A" }}
            />
          </div>
        </div>
        <div style={{ display: "flex" }}>
          <div
            className={"selectPassengerTileIcon"}
            id="StasiunAsal-value"
            onClick={() => OnChangePassengerNumber("sub")}
          >
            <RemoveCircleIcon
              className="passengerIcons"
              style={{
                color: `${
                  passenger === minPassengerCounts[passengerType]
                    ? "rgba(0,0,0,0.2)"
                    : theme.palette.primary.main
                }`,
              }}
            />
          </div>
          <div className={"selectPassengerTile"} id="StasiunAsal-value">
            <Typography
              children={passenger}
              className={"selectInputDataFont"}
              id="StasiunAsal-name"
            />
          </div>
          <div
            className={"selectPassengerTileIcon"}
            id="StasiunAsal-value"
            onClick={() => OnChangePassengerNumber("add")}
          >
            <AddCircleIcon className="passengerIcons" />
          </div>
        </div>
      </div>
      <div>
        <Typography
          style={{
            display: passengerError ? "" : "none",
            color: "#d32f2f",
            fontSize: "14px",
            fontWeight: "400",
          }}
        >
          {passengerError}
        </Typography>
      </div>
    </>
  );
};

export default PassengerSelectionField;